@import url('https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;600&display=swap');

body {
    font-family: 'Baloo 2', sans-serif; /* Set the playful font as default */
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: white;
}


  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  h1 {
    text-align: center;
    color: purple;
  }
  
  h2 {
    color: purple;
  }
  
  h5 {
    text-align: center;
    color: purple;
  }
  
  p {
    color: purple;
  }

  i {
    color: purple;
  }
  
  img.apple {
    padding-left: 60px
  }
  
  .container {
    display: flex;
    justify-content: center;
    list-style-type: none;
  }
  .center {
    width: 30%;
  }
  .form-center {
    width: 50%;
    padding-left: 25%;
  }
  
  .ul {
    display: flex;
    justify-content: center;
    
  }

  li {
    color: purple
  }
  
  
